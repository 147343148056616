import React from "react";

const initialState = {
  slug: "",
  modalOpen: false,
  content: {
    mainImage: { asset: {} },
    body: [
      {
        list: null,
        style: "",
        _type: "block",
        children: [{ _key: "", _type: "span", text: "" }],
      },
    ],
    title: "",
    slug: { current: "" },
  },
  showModal: (data: any) => {},
  hideModal: () => {},
};

const ModalContext = React.createContext(initialState);

class ModalProvider extends React.Component {
  showModal = (data: any) => {
    this.setState({
      content: data,
      modalOpen: true,
    });
  };

  hideModal = () => this.setState({ modalOpen: false });

  state = {
    slug: initialState.slug,
    modalOpen: initialState.modalOpen,
    content: initialState.content,
    showModal: this.showModal,
    hideModal: this.hideModal,
  };

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export default ModalContext;

export { ModalProvider };
