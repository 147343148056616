import React from "react";

import "@fontsource/lato";
import "@fontsource/poppins";

import { ModalProvider } from "./src/context/modalContext";

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
);
